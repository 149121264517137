<template>
  <button
    v-tooltip="{
      content: tooltipText,
    }"
    type="button"
    @click.stop="copyText"
  >
    <CopyIcon class="w-full h-full" />
  </button>
</template>

<script setup>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { CopyIcon } from "@/components/icons"

const props = defineProps({
  copyValue: String,
})
const { t } = useI18n()
const tooltipText = ref(t("general.copy"))
const copyText = async () => {
  try {
    await navigator.clipboard.writeText(props.copyValue)
    tooltipText.value = t("general.copied")
  } catch (err) {
    console.error(err)
  }
}
</script>
